<template>
  <g
    :transform="
      `scale(1, -1) translate(${xCoord +
        (position === 'left' ? -1 : position === 'right' ? 1 : 0) *
          200}, ${-yCoord +
        (position === 'top' ? 1 : position === 'bottom' ? -1 : 0) * 200})`
    "
  >
    <text
      style="sans-serif"
      :font-size="250 * refSlider"
      data-layer="F.SilkS"
      data-text-type="user"
      x="0"
      :y="-radius"
      text-anchor="start"
      dominant-baseline="middle"
      writing-mode="tb"
      data-text-hide="false"
      v-show="position === 'top' && showRefs && searchRefs"
      >{{ labelString }}</text
    >
    <text
      style="sans-serif"
      :font-size="250 * refSlider"
      data-layer="F.SilkS"
      data-text-type="user"
      :x="radius"
      y="0"
      text-anchor="start"
      dominant-baseline="middle"
      writing-mode="lr"
      data-text-hide="false"
      v-show="position === 'right' && showRefs && searchRefs"
      >{{ labelString }}</text
    >
    <text
      style="sans-serif"
      :font-size="250 * refSlider"
      data-layer="F.SilkS"
      data-text-type="user"
      :x="-radius"
      y="0"
      text-anchor="end"
      dominant-baseline="middle"
      writing-mode="lr"
      data-text-hide="false"
      v-show="position === 'left' && showRefs && searchRefs"
      >{{ labelString }}</text
    >
    <text
      style="sans-serif"
      :font-size="250 * refSlider"
      data-layer="F.SilkS"
      data-text-type="user"
      x="0"
      :y="radius"
      text-anchor="end"
      dominant-baseline="middle"
      writing-mode="tb"
      data-text-hide="false"
      v-show="position === 'bottom' && showRefs && searchRefs"
      >{{ labelString }}</text
    >
    <line x1="0" y1="0" x2="0" y2="200" stroke="black" stroke-width="10"  v-show="position === 'bottom' && showRefs && searchRefs" />
    <line :x1="radius" y1="0" x2="200" y2="0" stroke="black" stroke-width="10" v-show="position === 'left' && showRefs && searchRefs" />
    <line x1="0" y1="-200" x2="0" y2="0" stroke="black" stroke-width="10" v-show="position === 'top' && showRefs && searchRefs" />
    <line x1="-200" y1="0" x2="0" y2="0" stroke="black" stroke-width="10" v-show="position === 'right' && showRefs && searchRefs" />
  </g>
</template>
<script>
export default {
  props: {
    xCoord: {
      type: Number,
      required: false,
      default: 0,
    },
    yCoord: {
      type: Number,
      required: false,
      default: 0,
    },
    labelString: {
      type: String,
      required: false,
      default: null,
    },
    radius: {
      type: Number,
      required: false,
      default: 1.4,
    },
    showRefs: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchRefString: {
      type: String,
      required: false,
      default: null,
    },
    refSlider: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      position: "left",
    };
  },
  computed: {
    searchRefs() {
      return this.searchRefString
        ? this.labelString
            .toLowerCase()
            .match(this.searchRefString.toLowerCase())
        : true;
    },
  },
  methods: {
    updateLabelPosition(position) {
      this.position = position;
    },
  },
};
</script>
